.main-channel-pub-cls{
    .channel-card {
        background-color: #2A2A2A;
        border-radius: 16px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        padding: 30px;
        max-width: 400px;
        @media (max-width: 767px) {
            max-width: 100%;
            border-radius: 0px;
        }
    }

    .channel-logo {
        background-color: #ffffff;
        border-radius: 50%;
        width:90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 36px;
        font-weight: bold;
        margin: 0 auto 20px;
    }
    .channel-name {
        font-size: 20px;
        color: white;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .subscribers {
        font-size: 14px;
        color: #B5B5B5;
        margin-bottom: 20px;
    }
    .btn-preview {
        margin-top: 20px;
        width: 100%;
        position: relative;
        color: #fff;
        &:hover {
            color: #1977f2;
        }
    }
}