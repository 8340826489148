//
// authentication.scss
//

.auth-body-bg {
    padding: 0;
    background-color: $card-bg;
    font-family: Inter, Helvetica;  
}
.bg-img{
    background-image: url("../../../images/auth/logo.png");
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.left-page{
    width: 50%;
    height: 100vh;
}

.authentication-bg {
    background-image: url("../../../images/authentication-bg.jpg");
    height: 100vh;
    background-size: cover;
    background-position: center;
    .bg-overlay {
        background-color: $nero;
    }

    @media (max-width: 991px) {
        display: none;
    }
}
.container-fluid {
    background-color: var(--Babyblue);
}
p.key-seed {
    text-align: left;
    font-size: 18px !important;
    margin-left: 10px;
    margin-bottom: 5px;
    font-weight: 900;
}

button.login-btn {
    margin-top: 15px;
}
.left-side img{
    width: 115px;
}
textarea.login-input {
    overflow: auto;
    border: 1px solid $gray-500;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 12px;
}
.plicy p {
    font-size: 16px;
    color: var(--black);
    margin-bottom: 0px;
}
.plicy {
    position: absolute;
    bottom: 35px;
}
.left-page-cls h1 {
    position: relative;
    font-size: 55px;
    margin-top: 13px;
    margin-bottom: 0;
    color: var(--black);
    font-weight: 700;
}
.left-page-cls p {
    font-weight: 800;
    font-size: 18px;
    text-align: center;
    color: var(--logotext);
}
.login-content{
    width: 500px;
    margin: 0 auto;
    h1{
        color: var(--black);
        font-weight: 700;
        font-size: 38px;
        margin-top: 10px;
    }
    .txt-cls {
        text-align: left;
        textarea {
            overflow: auto;
            border: 1px solid $gray-500;
            width: 100%;
            padding: 10px;
            margin-bottom: 25px;
            font-size: 18px;
            border-radius: 10px;
            resize: none;
        }
        .register-textarea{
            resize: none;
            border-bottom: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border: 0px;
        }
    }
    .label-cls {
        font-size: 20px;
        margin-left: 10px;
        margin-bottom: 10px;
        color: var(--black);
        font-weight: 700;
    }
    .termscondition {
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        a {
            color: $skyblue;
            font-weight: 600;
        }
        @media (max-width:475px) {
            position: relative;
            transform: initial;
            bottom: initial;
            left: inherit;
            margin-top: 10px;    
        }
    }
    h4{
        line-height: 1.4;
        color: var(--black);
        font-weight: 400;
        font-size: 17px;
        margin-bottom: 0px;
    }
    p {
        font-size: 17px;
        color: var(--black);
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .inner-txt-cls {
        text-align: left;
        font-weight: 600;
        margin-top: 7px;
        margin-bottom: 30px;
        font-size: 13px;
        margin-left: 10px;
        color: $skyblue !important;
    }
    .icons {
        padding-right: 10px;
        text-align: right;
        background-color: var(--purewhite);
        padding-bottom: 8px;
        border-top: 0 !important;
        border-radius: 0 0 0 8px;
        border: var(--border-input) 1px solid;
    }
    .seed-detials {
        margin-top: 30px;
        background-color: var(--purewhite);
        overflow: hidden;
        border: 0px solid #eaecf0;
        border-radius: 10px;
    }
    .icon-btns {
        padding: 4px 7px;
        color: #B0B0B0;
        font-size: 14px;
        line-height: 1;
        margin-left: 15px;
    }
    .icon-btns img {
        padding-right: 7px;
        margin-top: -3px;
    }
    @media (max-width: 1024px) {
         p {
            font-size: 15px;
        }
        h4{
            font-size: 16px;
        }
        @media (max-width: 820px) {
             h4 {
                font-size: 14px;
            }
    }
    @media (max-width:435px) {
       .txt-cls textarea {
           margin-bottom: 21px;
           font-size: 14px;
          }
          button.login-btn {
            font-size: 14px;
            @media (max-width:320px) {
                font-size: 13px;
            }
          }
          h4 {
            font-size: 13px;
        }
}
    }
}

.main-top-reg {
    .seed-detials {
        .input-tag-container {
            border: var(--border-input) 1px solid;
            border-bottom: 0;
            border-radius: 8px 0 0;
            .ReactTags__tags .ReactTags__selected{
                border: 0px;
                
                .ReactTags__remove{
                    display: none;
                }
            }
        }
    }
}

img.logo-icon{
    width: 200px;
}
.login-cls{
    width: 50%;
    border-radius: 70px 0px 0px 70px;
    box-shadow: 0px 0px 116.8px #1877f266;
    background: var(--bgcolorchat);
    position: relative;
    z-index: 10;
    padding-top: 0px !important;
    height: 100vh;
    justify-content: center;
    padding: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.auth-form-group-custom {
    position: relative;
    .form-control {
        height: 60px;
        padding-top: 28px;
        padding-left: 60px;
    }

    label {
        position: absolute;
        top: 7px;
        left: 60px;
    }

    .auti-custom-input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 19px;
        font-size: 24px;
        color: $primary;
    }
}

.auth-logo {
    &.logo-light {
        display: $display-none;
    }
    &.logo-dark {
        display: $display-block;
    }
}

[data-bs-theme="dark"] {
    .authentication-logo {
        .logo-light {
            display: block;
        }
        .logo-dark {
            display: none;
        }
    }
}
.left-page-cls{
    @media (max-width: 1024px) {
         h1 {
            font-size: 45px;
        }
         p {
            font-size: 16px;
        }
    }
    @media (max-width: 820px) {
         h1 {
            font-size: 35px;
        }
         p {
            font-size: 14px;
        }
    }
}
@media (max-width: 1024px) {
    img.logo-icon {
        width: 185px;
    }
    .login-content p {
        font-size: 13px;
    }
}
@media (max-width: 820px) {
    .left-side img {
        width: 90px;
    }
    .login-content h1 {
        font-size: 30px;
    }
    .login-content p {
        font-size: 14px;
    }
    .left-page{
        display: none;
    }
    .login-cls{
        width: 100%;
        border-radius: 0px 0px 0px 0px;
    }
}
@media (max-width: 426px) {
    .left-page{
        display: none;
    }
    .login-cls{
        width: 100%;
        padding: 35px 20px;
        border-radius: 0px 0px 0px 0px;
        min-height: 100vh;
        height: auto;
        box-shadow: none;
        @media (max-width:320px) {
            padding: 20px;
        }
    }
}

.swagger-ui {
    background-color: #fff;
    padding: 15px;
    border-radius: 14px;
    max-height: calc(100vh - 115px);
    overflow: hidden;
    overflow-y: auto;
}