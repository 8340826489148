//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

.btn {
  &:hover,
  &:active,
  &:focus,
  &.active,
  &.show {
    border-color: transparent !important;
  }
}

.btn-light {
  border: 1px solid var(--#{$prefix}border-color);

  &.active,
  &:hover,
  &:focus {
    background-color: $gray-200;
    border: 1px solid var(--#{$prefix}border-color);
  }
}

.btn-check + .btn {
  &:hover {
    border-color: var(--#{$prefix}btn-border-color) !important;
  }
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: $gray-200;
}

.btn-outline-light {
  color: $gray-900;
}
.no-focus-outline:focus-visible {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
// theme dark
[data-bs-theme="dark"] {
  .btn-light {
    color: $white !important;
    background-color: var(--#{$prefix}light);
    border-color: var(--#{$prefix}light);

    &:hover {
      color: $white !important;
      background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
      border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    }
  }

  .btn-check:focus + .btn-light,
  .btn-check:focus + .btn-outline-light,
  .btn-check:focus + .btn-soft-light,
  .btn-light:focus,
  .btn-outline-light:focus,
  .btn-soft-light:focus {
    color: $white !important;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    // border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
  }

  .btn-check:active + .btn-light,
  .btn-check:active + .btn-outline-light,
  .btn-check:active + .btn-soft-light,
  .btn-check:checked + .btn-light,
  .btn-check:checked + .btn-outline-light,
  .btn-check:checked + .btn-soft-light,
  .btn-light.active,
  .btn-light:active,
  .btn-outline-light.active,
  .btn-outline-light:active,
  .btn-soft-light.active,
  .btn-soft-light:active,
  .show > .btn-light.dropdown-toggle,
  .show > .btn-outline-light.dropdown-toggle,
  .show > .btn-soft-light.dropdown-toggle {
    color: $white !important;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    // border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
  }

  // dark

  .btn-dark {
    color: $white;
    background-color: var(--#{$prefix}dark);
    border-color: var(--#{$prefix}dark);

    &:hover {
      background-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
      border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
    }
  }

  .btn-check:focus + .btn-dark,
  .btn-check:focus + .btn-outline-dark,
  .btn-check:focus + .btn-soft-dark,
  .btn-dark:focus,
  .btn-outline-dark:focus,
  .btn-soft-dark:focus {
    color: $white;
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
    border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
  }

  .btn-check:active + .btn-dark,
  .btn-check:active + .btn-outline-dark,
  .btn-check:active + .btn-soft-dark,
  .btn-check:checked + .btn-dark,
  .btn-check:checked + .btn-outline-dark,
  .btn-check:checked + .btn-soft-dark,
  .btn-dark.active,
  .btn-dark:active,
  .btn-outline-dark.active,
  .btn-outline-dark:active,
  .btn-soft-dark.active,
  .btn-soft-dark:active,
  .show > .btn-dark.dropdown-toggle,
  .show > .btn-outline-dark.dropdown-toggle,
  .show > .btn-soft-dark.dropdown-toggle {
    color: var(--#{$prefix}light);
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
    border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
  }

  // outline

  .btn-outline-light {
    color: $white !important;
    border-color: var(--#{$prefix}light);

    &:hover {
      color: $white;
      background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
      border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    }
  }

  .btn-outline-dark {
    color: $white !important;
    border-color: var(--#{$prefix}dark);

    &:hover {
      color: var(--#{$prefix}light);
      background-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
      border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
    }
  }

  // soft

  .btn-soft-light {
    color: $white !important;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.1);

    &:hover {
      color: $white !important;
      background-color: var(--#{$prefix}light);
    }
  }

  .btn-soft-dark {
    color: $white !important;
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.1);

    &:hover {
      color: var(--#{$prefix}light);
      background-color: var(--#{$prefix}dark);
    }
  }
}
